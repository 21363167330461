import axios from 'axios';
import { getCookie, setCookie, removeCookie } from '../util/cookieUtil';

const baseURL = process.env.REACT_APP_API_URL + '/api';

const apiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = getCookie('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getCookie('refreshToken');
            try {
                const response = await axios.get(`${baseURL}/oauth2/refresh`, {
                    withCredentials: true,
                });

                if (response.data.accessToken) {
                    setCookie('accessToken', response.data.accessToken, 1);
                    apiClient.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
                    originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
                    return apiClient(originalRequest);
                }
            } catch (err) {
                console.error('Failed to refresh access token:', err);
                removeCookie('accessToken');
                removeCookie('refreshToken');
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
